import React, { SVGProps } from 'react'
import { ReactComponent as Globe } from '@meprism/shared/src/assets/icons/Globe.svg'
import { ReactComponent as ShieldTwoTone } from '@meprism/shared/src/assets/icons/ShieldTwoTone.svg'
import { ReactComponent as User } from '@meprism/shared/src/assets/icons/User.svg'
import { ReactComponent as UserTwoTone } from '@meprism/shared/src/assets/icons/UserTwoTone.svg'
import { ReactComponent as TonedSetting } from '@meprism/shared/src/assets/icons/TonedSetting.svg'
import { ReactComponent as TonedSettingTwoTone } from '@meprism/shared/src/assets/icons/TonedSettingTwoTone.svg'
import { ReactComponent as Home } from '@meprism/shared/src/assets/icons/Home.svg'
import { ReactComponent as HomeTwoTone } from '@meprism/shared/src/assets/icons/HomeTwoTone.svg'
import { ReactComponent as Alert } from '@meprism/shared/src/assets/icons/Alert.svg'
import { ReactComponent as TrashCan } from '@meprism/shared/src/assets/icons/TrashCan.svg'
import { ReactComponent as Info } from '@meprism/shared/src/assets/icons/Info.svg'
import { ReactComponent as ThumbsUp } from '@meprism/shared/src/assets/icons/thumbs/thumbs_up.svg'
import { ReactComponent as ThumbsUpSelected } from '@meprism/shared/src/assets/icons/thumbs/thumbs_up_selected.svg'
import { ReactComponent as ThumbsDown } from '@meprism/shared/src/assets/icons/thumbs/thumbs_down.svg'
import { ReactComponent as ThumbsDownSelected } from '@meprism/shared/src/assets/icons/thumbs/thumbs_down_selected.svg'
import { ReactComponent as Close } from '@meprism/shared/src/assets/icons/Close.svg'
import { ReactComponent as Collapse } from '@meprism/shared/src/assets/icons/Collapse.svg'
import { ReactComponent as Unread } from '@meprism/shared/src/assets/icons/Unread.svg'
import { ReactComponent as Back } from '@meprism/shared/src/assets/icons/Back.svg'
import { ReactComponent as Lightbulb } from '@meprism/shared/src/assets/icons/LightBulb.svg'
import { ReactComponent as Error } from '@meprism/shared/src/assets/icons/Error.svg'
import { ReactComponent as ErrorNew } from '@meprism/shared/src/assets/icons/check-close.svg'
import { ReactComponent as SuccessNew } from '@meprism/shared/src/assets/icons/check-circle.svg'
import { ReactComponent as Warning } from '@meprism/shared/src/assets/icons/Warning.svg'
import { ReactComponent as Success } from '@meprism/shared/src/assets/icons/Success.svg'
import { ReactComponent as NotMe } from '@meprism/shared/src/assets/icons/NotMe.svg'
import { ReactComponent as Me } from '@meprism/shared/src/assets/icons/Me.svg'
import { ReactComponent as Deleted } from '@meprism/shared/src/assets/icons/Deleted.svg'
import { ReactComponent as Profile } from '@meprism/shared/src/assets/icons/Profile.svg'
import { ReactComponent as AppleLogo } from '@meprism/shared/src/assets/icons/logos/Apple.svg'
import { ReactComponent as GoogleLogo } from '@meprism/shared/src/assets/icons/logos/Google.svg'
import { ReactComponent as IosButton } from '@meprism/shared/src/assets/buttons/ios_download.svg'
import { ReactComponent as GoogleButton } from '@meprism/shared/src/assets/buttons/google_download.svg'
import { ReactComponent as MeprismPrivacy } from '@meprism/shared/src/assets/icons/logos/logo_privacy.svg'
import { ReactComponent as ThreeDots } from '@meprism/shared/src/assets/icons/ThreeDots.svg'
import { ReactComponent as AuthenticatorTwoTone } from '@meprism/shared/src/assets/icons/AuthenticatorTwoTone.svg'
import { ReactComponent as Invite } from '@meprism/shared/src/assets/icons/Invite.svg'
import { ReactComponent as MePrismBusiness } from '@meprism/shared/src/assets/icons/logos/logo_business.svg'
import { ReactComponent as UploadDocument } from '@meprism/shared/src/assets/icons/Upload_Document.svg'
import { ReactComponent as Document } from '@meprism/shared/src/assets/icons/Document.svg'
import { ReactComponent as ThreeDotsBlue } from '@meprism/shared/src/assets/icons/ThreeDotsBlue.svg'
import { ReactComponent as Phone } from '@meprism/shared/src/assets/icons/Phone.svg'
import { ReactComponent as Authenticator } from '@meprism/shared/src/assets/icons/Authenticator.svg'
import { ReactComponent as CirclePlus } from '@meprism/shared/src/assets/icons/CirclePlus.svg'
import { ReactComponent as HourglassTwoTone } from '@meprism/shared/src/assets/icons/HourglassTwoTone.svg'
import MpTheme from '../../../config/MpTheme'
// type SvgIconProps = SVGProps<SVGSVGElement>

type SvgIconProps = SVGProps<SVGSVGElement> & { title?: string }

export type MpIconList =
  | 'globe'
  | 'shieldTwoTone'
  | 'appleLogo'
  | 'googleLogo'
  | 'user'
  | 'userTwoTone'
  | 'tonedSetting'
  | 'tonedSettingTwoTone'
  | 'home'
  | 'homeTwoTone'
  | 'alert'
  | 'mePrismPrivacy'
  | 'trashCan'
  | 'info'
  | 'thumbsUp'
  | 'thumbsUpSelected'
  | 'thumbsDown'
  | 'thumbsDownSelected'
  | 'close'
  | 'collapse'
  | 'unread'
  | 'back'
  | 'lightbulb'
  | 'error'
  | 'errornew'
  | 'successnew'
  | 'warning'
  | 'success'
  | 'notMe'
  | 'me'
  | 'deleted'
  | 'profile'
  | 'iosButton'
  | 'googleButton'
  | 'threeDots'
  | 'authenticatorTwoTone'
  | 'invite'
  | 'mePrismBusiness'
  | 'uploadDocument'
  | 'document'
  | 'threeDotsBlue'
  | 'phone'
  | 'authenticator'
  | 'circlePlus'
  | 'hourglassTwoTone'

const icons: { [key in MpIconList]: (svgProps: SvgIconProps) => JSX.Element } =
  {
    globe: (svgProps: SvgIconProps) => <Globe {...svgProps} />,
    shieldTwoTone: (svgProps) => <ShieldTwoTone {...svgProps} />,
    appleLogo: (svgProps: SvgIconProps) => <AppleLogo {...svgProps} />,
    googleLogo: (svgProps: SvgIconProps) => <GoogleLogo {...svgProps} />,
    mePrismPrivacy: (svgProps) => <MeprismPrivacy {...svgProps} />,
    user: (svgProps) => <User {...svgProps} />,
    userTwoTone: (svgProps) => <UserTwoTone {...svgProps} />,
    tonedSetting: (svgProps) => <TonedSetting {...svgProps} />,
    tonedSettingTwoTone: (svgProps) => <TonedSettingTwoTone {...svgProps} />,
    home: (svgProps) => <Home {...svgProps} />,
    homeTwoTone: (svgProps) => <HomeTwoTone {...svgProps} />,
    alert: (svgProps) => <Alert {...svgProps} />,
    trashCan: (svgProps) => <TrashCan {...svgProps} />,
    info: (svgProps) => <Info {...svgProps} />,
    thumbsUp: (svgProps) => <ThumbsUp {...svgProps} />,
    thumbsUpSelected: (svgProps) => <ThumbsUpSelected {...svgProps} />,
    thumbsDown: (svgProps) => <ThumbsDown {...svgProps} />,
    thumbsDownSelected: (svgProps) => <ThumbsDownSelected {...svgProps} />,
    close: (svgProps) => <Close {...svgProps} />,
    collapse: (svgProps) => <Collapse {...svgProps} />,
    unread: (svgProps) => <Unread {...svgProps} />,
    back: (svgProps) => <Back {...svgProps} />,
    lightbulb: (svgProps) => <Lightbulb {...svgProps} />,
    warning: (svgProps) => <Warning {...svgProps} />,
    error: (svgProps) => <Error {...svgProps} />,
    errornew: (svgProps) => <ErrorNew {...svgProps} />,
    success: (svgProps) => <Success {...svgProps} />,
    successnew: (svgProps) => <SuccessNew {...svgProps} />,
    notMe: (svgProps) => <NotMe {...svgProps} />,
    me: (svgProps) => <Me {...svgProps} />,
    deleted: (svgProps) => <Deleted {...svgProps} />,
    profile: (svgProps) => <Profile {...svgProps} />,
    iosButton: (svgProps) => <IosButton {...svgProps} />,
    googleButton: (svgProps) => <GoogleButton {...svgProps} />,
    threeDots: (svgProps) => <ThreeDots {...svgProps} />,
    authenticatorTwoTone: (svgProps) => <AuthenticatorTwoTone {...svgProps} />,
    invite: (svgProps) => <Invite {...svgProps} />,
    mePrismBusiness: (svgProps) => <MePrismBusiness {...svgProps} />,
    uploadDocument: (svgProps) => <UploadDocument {...svgProps} />,
    document: (svgProps) => <Document {...svgProps} />,
    threeDotsBlue: (svgProps) => <ThreeDotsBlue {...svgProps} />,
    phone: (svgProps) => <Phone {...svgProps} />,
    authenticator: (svgProps) => <Authenticator {...svgProps} />,
    circlePlus: (svgProps) => <CirclePlus {...svgProps} />,
    hourglassTwoTone: (svgProps) => <HourglassTwoTone {...svgProps} />,
  }

export interface MpSvgIconProps extends SvgIconProps {
  icon: MpIconList
}

const defaultProps = {
  color: MpTheme.colors.secondary['800'],
}

const MpSvgIcon = (props: MpSvgIconProps) => {
  const { icon, ...extraProps } = props

  return icons[icon]({ ...defaultProps, ...extraProps })
}

// export const MpMuiIcon = (props: MpSvgIconProps) => {
//   const { icon, color, ...extraProps } = props
//
//   return <SvgIcon component={icons[icon]} color={color} {...extraProps} />
// }

export type TwoToneSvg = {
  [T in MpIconList]: `${T}TwoTone` extends MpIconList ? T : never
}[MpIconList]

export default MpSvgIcon
