import { CognitoUser } from '@aws-amplify/auth'
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'

export type AuthContextType = {
  user: CognitoUser | null | undefined
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null | undefined>>
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<CognitoUser | null>()

  useEffect(() => {
    (async () => {
    if (user != null) return

      try {
        setUser(await Auth.currentAuthenticatedUser())
      } catch (error) {
        console.error('No authenticated user:', error)
        setUser(null)
      }
    })()
    }, [user])

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
